<template>
  <router-view v-bind:id="id"></router-view>
</template>

<script>
export default {
  name: "PlainRouterView",
  props: ['id']
}
</script>

<style scoped>

</style>